<template>
    <v-row>
        <v-col align="center" cols="12">
            <v-card max-width="500px" dark class="px-5 py-2" flat>
                <v-select
                    :loading="loading"
                    :disabled="loading"
                    label="Studios"
                    :items="availableStudios"
                    v-model="selectedStudios"
                    multiple
                ></v-select>
            </v-card>
        </v-col>
        <v-col align="center" cols="12" v-if="loading">
            <v-progress-circular color="primary" indeterminate></v-progress-circular>
        </v-col>
        <v-col align="center" cols="12" v-if="requestsFiltered.length === 0">
            <v-alert max-width="500px" type="info">Keine Anfragen gefunden</v-alert>
        </v-col>
        <v-col align="center" v-for="customer of requestsFiltered" :key="customer.userId" class="mb-2" cols="12">
            <v-card class="rounded-lg" align="left" max-width="500px" dark flat>
                <v-card-subtitle class="mb-0 pb-0">{{ studiosOfRequest(customer.requests) }}</v-card-subtitle>
                <v-card-title class="mt-0 pt-0">{{ customer.name }}</v-card-title>
                <v-card-subtitle>{{ customer.email }}</v-card-subtitle>
                <v-row no-gutters>
                    <v-col cols="12" v-for="request of customer.requests" :key="request.id">
                        <v-row no-gutters>
                            <v-col cols="8">
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <v-card-subtitle class="mb-2 pb-0">
                                            <v-chip small color="primary">
                                                {{ $moment(request.time).format('DD.MM.YY HH:mm') }} Uhr
                                            </v-chip>
                                        </v-card-subtitle>
                                        <v-card-title class="h5 mb-0 pb-0 mt-0 pt-0">
                                            {{ request.contract.membershipName }}
                                        </v-card-title>
                                        <v-card-subtitle
                                            v-if="request.contract.packages && request.contract.packages.length > 0"
                                            class="mb-2 pb-0 mt-3"
                                        >
                                            Zusatzpakete: {{ request.contract.packages.map((e) => e.name).join(', ') }}
                                        </v-card-subtitle>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-card-subtitle class="mt-2 pt-0">
                                            {{ $moment(request.contract.startDate).format('DD.MM.YYYY') }} bis
                                            {{ $moment(request.contract.endDate).format('DD.MM.YYYY') }}
                                            <span class="font-weight-bold" v-if="request.year.toString() !== '1'">
                                                ({{ request.year }}tes Jahr)
                                            </span>
                                        </v-card-subtitle>
                                    </v-col>
                                </v-row>
                            </v-col>

                            <v-col class="d-flex justify-end align-center" cols="4">
                                <v-btn
                                    @click="
                                        selectedRequest = {
                                            request,
                                            customerInfos: customer,
                                            customer: customer.userId,
                                            amount: 0,
                                        }
                                    "
                                    color="orange"
                                    class="mr-5"
                                >
                                    <v-icon>mdi-lock-open</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-dialog max-width="500px" persistent :value="selectedRequest !== null">
            <v-card
                v-if="
                    selectedRequest &&
                    selectedRequest.request &&
                    selectedRequest.request.contract &&
                    selectedRequest.request.contract.membershipName
                "
            >
                <v-card-title style="background-color: var(--v-primary)" class="white--text">
                    {{ selectedRequest.request.contract.membershipName }}
                </v-card-title>
                <v-alert class="ma-5" color="orange" type="info" v-if="selectedRequest.request.year.toString() !== '1'">
                    {{ selectedRequest.request.year }}tes Jahr der Mitgliedschaft
                </v-alert>
                <v-card-text class="mt-5 pb-0">
                    Studio:
                    {{ selectedRequest.request.studioPopulated.name }}
                </v-card-text>
                <v-card-text
                    v-if="
                        selectedRequest.request.contract.packages &&
                        selectedRequest.request.contract.packages.length > 0
                    "
                    class="mt-2 pb-0"
                >
                    Zusatzpakete:
                    {{ selectedRequest.request.contract.packages.map((e) => e.name).join(', ') }}
                </v-card-text>
                <v-card-text class="mt-5 pb-0">
                    Kunde:
                    {{ selectedRequest.customerInfos.name }} ({{ selectedRequest.customerInfos.email }})
                </v-card-text>
                <v-card-text class="mt-0 mb-0 pb-0">
                    Laufzeit: {{ $moment(selectedRequest.request.contract.startDate).format('DD.MM.YYYY') }} bis
                    {{ $moment(selectedRequest.request.contract.endDate).format('DD.MM.YYYY') }}
                </v-card-text>

                <v-text-field
                    class="mx-5 mt-5"
                    type="number"
                    v-model="selectedRequest.amount"
                    label="Kosten des Abos (KK-Bestätigung)"
                ></v-text-field>
                <v-alert class="mx-5" type="info">
                    Der Kunde wird per E-Mail über die Freischaltung der Krankenkassenbestätigung informiert. Das Abo
                    kann im Nachhinein über die Benutzersuche -> Verträge -> TAC Verträge angepasst werden.
                </v-alert>
                <v-col cols="12" class="text-center">
                    <v-btn color="orange" class="white--text" @click="createPDF(selectedRequest)">
                        Test PDF erstellen
                    </v-btn>
                </v-col>
                <v-card-actions>
                    <v-btn @click="selectedRequest = null" text>Abbrechen</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="updateContractPayment" color="primary">Freischalten</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import api from '@/api'

export default {
    components: {},
    data() {
        return {
            loading: true,
            selectedStudios: [],
            requests: [],
            selectedRequest: null,
        }
    },
    created() {
        this.fetchHealthcareRequests()
    },
    methods: {
        async createPDF(data) {
            let request = data.request
            let formData = {
                test: true,
                contract: {
                    startDate: request.contract.startDate,
                    endDate: request.contract.endDate,
                    amount: data.amount,
                    packages:
                        request.contract.packages && request.contract.packages.length > 0
                            ? request.contract.packages.map((e) => e.name)
                            : [],
                    membershipName: request.contract.membershipName,
                    studioId: request.studioPopulated._id,
                    studioPopulated: {
                        art: null,
                        adresse: null,
                        healtcareConfirmationNumber: null,
                        logo: null,
                        website: null,
                    },
                },
                customerId: data.customer,
                customerInfos: {
                    healthcareName: 'Testversicherung',
                    additionalInsurance: 'Testzusatzversicherung',
                    insuranceNumber: 'Testversicherungsnummer',
                    firstName: 'Vorname',
                    lastName: 'Nachname',
                    street: 'Strasse',
                    zip: 'PLZ',
                    city: 'Stadth',
                    signature: null,
                },
            }

            try {
                this.$toast.success('PDF wird erstellt')
                await api.createHealthcarePDF(formData)
            } catch (e) {
                this.$toast.error('Fehler beim herunterladen, bitte versuche es erneut')
            }
        },
        async updateContractPayment() {
            if (
                this.selectedRequest?.amount > 0 &&
                this.selectedRequest?.request?.id &&
                this.selectedRequest?.customer
            ) {
                let updateRequestData = {
                    amount: this.selectedRequest.amount,
                    value: true,
                    year: this.selectedRequest.request.year,
                    contractIdSequenceId: this.selectedRequest.request.id.toString(),
                    userId: this.selectedRequest.customer,
                }
                try {
                    let status = await api.updateTACContractPaidStatus(updateRequestData)
                    console.log(status)
                    this.$toast.success('Status aktualisiert')
                    this.selectedRequest = null
                    this.requests = []
                    this.fetchHealthcareRequests()
                } catch (e) {
                    this.$toast.error('Fehler beim aktualisieren')
                }
            } else {
                this.$toast.error('Fülle alle Felder aus')
            }
        },
        studiosOfRequest(requests) {
            let studios = ''
            try {
                studios = [...new Set(requests.map((e) => e.studioPopulated.name))].join(', ')
            } catch (e) {
                studios = 'Fehler'
            }
            return studios
        },
        async fetchHealthcareRequests() {
            this.loading = true
            try {
                let requests = await api.fetchHealthcareRequests()

                this.requests = requests.map((e) => {
                    return {
                        email: e.email,
                        userId: e._id,
                        name: e.vorname + ' ' + e.nachname,
                        requests: e.notPaidRequests,
                    }
                })
                this.selectedStudios = this.availableStudios
            } catch (e) {
                this.$error('Fehler beim Laden der Anfragen')
            }
            this.loading = false
        },
    },
    computed: {
        requestsFiltered() {
            if (this.requests.length > 0) {
                return this.requests.filter((e) =>
                    e.requests.find((r) => this.selectedStudios.includes(r.studioPopulated.name))
                )
            } else {
                return []
            }
        },
        availableStudios() {
            let names = []
            if (this.requests.length > 0) {
                names = this.requests
                    .flatMap((e) => e.requests.map((r) => r.studioPopulated.name))
                    .filter((name) => name)
                    .sort()
            }

            return [...new Set(names)]
        },
    },
}
</script>

<style lang="scss" scoped>
.studio-name {
    font-weight: bold;
}

.time {
    font-size: 12px;
    color: #999;
}

.contract-name {
    font-style: italic;
}
</style>